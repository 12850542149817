.confirmation {
	&__group {
		display: flex;
		flex-flow: row wrap;
		margin-bottom: 10px;
	}
	&__label {
	}
	&__value {
		flex: 1;
		text-align: right;
		word-break: break-word;
	}
	&__value--raw {
		width: 100%;
		word-break: break-word;
		margin: 0;
		padding-left: 20px;
	}
	hr {
  	border: none;
  	border-top: 1px dashed $color-grey;
	}
	.bold {
		font-weight: bold;
	}
}