.step {
    &__header-container {
        height: 60px;
        background-color: $color-grey-lighter;
        border: 1px dashed $color-grey;
        display: flex;
        padding: 0 10px;
        display: none;
        @include mobile {
            padding: 0;
        }
    }
    &__header {
        width: 60px;
        display: inline-flex;
        background-size: 24px;
        align-items : center;
        justify-content : center;
    }
    &__header-delimiter {
        flex : 1 1 auto;
        height: 30px;
        border-bottom: 1px solid $color-grey;
        &.active {
            border-bottom-color: $color-success;
        }
    }
    &__panels-container { 
    }
    &__panel {
        border-right: 1px solid $color-grey;
        border-left: 1px solid $color-grey;
        display: none;
        background-color: $color-white;
        padding: 20px 10px ;
        &.active {
            display: block;
        }
    }
    &__footer {     
        background-color: $color-white;
        position: sticky;
        position: -webkit-sticky;
        bottom: 0;
        box-shadow: 0 -2px 4px rgba(0,0,0,0.2);
        border: 1px solid $color-grey;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: center;
        -webkit-align-items: center;
        -webkit-justify-content: center;
        padding: 15px;
        flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
    }
    &__footer-text {
        opacity: 0.5;
    }
    &__footer-warning {
        color: $color-error;
    }
    &__footer-row {
        width: 100%;
        padding: 15px 0 0 0;
        text-align: center;
    }
    &__button {
        width: 100%;
        max-width: none;
        padding: 0;
        text-align: center;
        background-color: $color-primary;
        color: $color-white;
        cursor: pointer;
        margin-left: 30px;
        border-radius: 4px;
        border: 1px solid $color-primary;
        &:first-of-type {
            margin-left: 0;
        }
        &--inverse {
            color: $color-primary;
            background-color: $color-white;
        }
    }
    &__title {
        margin-top: 0;
    }
    &__reset, &__end {
        text-decoration: underline;
        cursor: pointer;
        &,&:hover,&:active,&:focus {
            color: $color-primary;
        }
    }
}