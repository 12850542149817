.car-reservation {
	&__row {
		padding: 0px 15px 10px;
	}
	&__list {
		margin: 0;
		padding: 0;
		background-color: $color-grey-light;
		display: flex;
		flex-flow: row wrap;
		padding: 0 7.5px;
	}	
	&__item-container {
		list-style: none;
		width: 100%;
		padding : 7.5px;
		display: flex;
		flex-flow: row wrap;
		margin: 0 auto;
		@include tablet-desktop {
			max-width: 1200px;
		}
		&--grey {
			background-color: $color-grey-light;
		}		
	}
	&__item {
		flex: 1 1 auto;
		width: 100%;
		padding: 7.5px;
		display: flex;
		@include tablet-desktop {
			width: 50%;
		}
		&--new &-content{
			border: 1px solid #589bcc;
			box-shadow: 1px 1px 3px #589bcc;
		}
	}
	&__item-content {
		flex: 1 1 auto;
		padding: 15px 10px;
		background-color: $color-white;
		line-height: 1.5em;
		border: 1px solid $color-grey;
		box-shadow: 1px 1px 1px rgba(0,0,0,0.1);
		&:after {
			content: '';
			display: block;
			clear: both;
		}
	}
	&__item-chip {
		padding: 10px;
		width: 100%;
		background-color: $color-grey-lightest;
		border-top : 1px solid $color-grey;
		cursor: pointer;
	} 
	.button-group {
		border-top : 1px solid $color-grey;

	}

}