.avatar {
	width: 40px;
	height: 40px;
    border-radius: 50%;
    background-size: cover;
    background-color: $color-primary;
    color: $color-white;
    display: inline-flex;
    align-items: center;
	justify-content: center;
	margin-right: 10px;
	float: left;
	//@include transition(all);
    &--small {
		width: 18px;
		height: 18px;
		margin-right: 4px;
    	vertical-align: bottom;
    }
}