.tip {
    &__container {
        margin-bottom: 15px;
    }
    &__selector {
        cursor: pointer;
        font-size: 12px;
        font-weight: bold;
        padding: 5px 7.5px;
        display: inline-block;
        border: 1px solid $color-grey;
        margin: 5px 7.5px 0 0;
        border-radius: 4px;
        &--active {
            background-color: $color-info;
            color: $color-white;
        }
        &--reset {
            width: 24px;
            @include icon(multiply, $color-black); 
		    background-size: 16px;
            cursor: pointer;
        }
    }
}