.hide {
    display: none!important;
}
.hide-mobile {
    @include-mobile {
        display: none!important;
    }
}
.hide-tablet {
    @include-tablet {
        display: none!important;
    }
}
.hide-desktop {
    @include-desktop {
        display: none!important;
    }
}
.hide-mobile-tablet {
    @include-mobile-tablet {
        display: none!important;
    }
}
.hide-tablet-desktop {
    @include-tablet-desktop {
        display: none!important;
    }
}
.text-left {
    text-align: left!important;
}
.text-center {
    text-align : center!important;
}
.text-right {
    text-align: right!important;
}
.text-left-mobile {
    @include-mobile {
        text-align: left!important;
    }
}
.text-center-mobile {
    @include-mobile {
        text-align : center!important;
    }
}
.text-right-mobile {
    @include-mobile {
        text-align: right!important;
    }
}