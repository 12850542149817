.nb-form-control {
	margin-bottom: 1rem;
	display: flex;
	display: -webkit-flex;
	flex-flow: row no-wrap;
	//align-items: center;
	position: relative;
	border: none;	
	padding: 0;
	&__group {
		display: flex;
	}
	&__group--expand {
		flex: 1;
	}
	&--right {
		justify-content: flex-end;
	}
	&--date-time {
		flex-flow: row no-wrap;
		.nb-form-control__input {
			width: 100%;
		}
	}
	&__space {
		width: 10px;
	}
	&__icon {
		margin-right: 5px;
		&--trip {
			&:before {
				@include icon-container(1.5em);
				@include icon(circle, $color-primary-dark);
				background-size: 75%!important;
			}
			&:after {
				@include icon-container(1.5em);
				@include icon(more-vert, $color-primary);
				position: absolute;	
				top: 88%;
				left: 0;
			}
		}
		&--place {
			&:before {
				@include icon-container(1.5em);
				@include icon(place, $color-primary-dark);
			}
		}
		&--person:before {
			@include icon-container(1.5em);
			@include icon(person, $color-primary-dark);
		}
		&--lock:before {
			@include icon-container(1.5em);
			@include icon(lock, $color-primary-dark);
		}
		&--phone:before {
			@include icon-container(1.5em);
			@include icon(local-phone, $color-primary-dark);
		}
		&--email:before {
			@include icon-container(1.5em);
			@include icon(email, $color-primary-dark);
		}
		&--more-place{
			cursor: pointer;
			&:before {
				@include icon-container(1.5em);
				@include icon(plus, $color-primary-dark);
			}
		}
		&--car:before {
			@include icon-container(1.5em);
			@include icon(car, $color-primary-dark);
		}
		&--filter:before {
			@include icon-container(1.5em);
			@include icon(filter, $color-primary-dark);
		}
		&--direction:before {
			@include icon-container(1.5em);
			@include icon(repeat, $color-primary-dark);
		}
		&--calendar:before {
			@include icon-container(1.5em);
			@include icon(calendar, $color-primary-dark);
		}
		&--clock:before {
			@include icon-container(1.5em);
			@include icon(clock, $color-primary-dark);
		}
		&--check:before {
			@include icon-container(1.5em);
			@include icon(check, $color-primary-dark);
		}
		&--flight:before {
			@include icon-container(1.5em);
			@include icon(flight, $color-primary-dark);
		}
		&--edit:before {
			@include icon-container(1.5em);
			@include icon(pencil, $color-primary-dark);
		}
		&--reset {
			opacity: 0;
			cursor: pointer;
			transition: opacity 0.3s ease;
			&:hover {
				opacity: 1;
			}
			&:before {
				@include icon-container(1.5em);
				@include icon(multiply, $color-primary-dark);
				background-size: 75%!important;
				position: absolute;
				z-index: 1;
				right: 0;
				top : 3px;
				background-color: $color-white;
			}
		}
		&--swap:before {
			@include icon-container(1.5em);
			@include icon(swap, $color-primary-dark);
		}
		&--landscape:before {
			@include icon-container(1.5em);
			@include icon(landscape, $color-primary-dark);
		}
	}
	&__input {
		border: none;
		width: 100%;
		flex: 1;
		-webkit-flex: 1;
		border-bottom: 1px solid $color-grey;
		padding: 0;
		height: 2em;
		@include transition(border);
		&:focus {
			outline: none;
			border-bottom-color: $color-primary;
		}	
		&:focus~.nb-form-control__icon--reset {
			opacity: 1;
			pointer-events: all;
		}
		&--textarea {
			height: 4em;
		}
	}
	&__select {
		width: 100%;
		border-bottom: 1px solid $color-grey;
		&:focus {
			outline: none;
			border-bottom-color: $color-primary;
		}
	}
	&__error {
		width: 100%;
		color: $color-error;
		font-size: 12px;
		font-style: italic;
		margin-top: 3px;
	}
}

.switch {
	&__popup {
		box-shadow: 0 1px 2px 0 rgba(0,0,0,.2);
		border-radius: 3px;
		border: 1px solid #ccc;
		max-width: 320px;
		overflow: hidden;
	}
	&__header {
		display: flex;
		border-bottom: 1px solid $color-grey-light;
	}
	&__header-item {
		padding: 10px;
		width: 50%;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		&.active {
			background-color: $color-grey-light;
		}
		&--calendar:before {
			@include icon-container(1.5em);
			@include icon(calendar, $color-primary-dark);
		}
		&--clock:before {
			@include icon-container(1.5em);
			@include icon(clock, $color-primary-dark);
		}
	}
	&__confirm {
		padding: 5px;
		.button {
			max-width: none;
			padding: 0; 
		}
		.btn-ripple {
			width: 100%;
			display: flex;
			padding: 10px 0 ;
			align-items: center;
			justify-content: center;
			&:before {
				@include icon-container(1.5em);
			@include icon(check, $color-white);
			}
		}
	}
}