.route {
	&__tab-container {
		display: flex;
		width: 100%;
		margin-bottom: 15px;
		
	}
	&__tab {
		height: 35px;
		align-items: center;
		justify-content: center;
		width: 50%;
		display: flex;
		cursor: pointer;
		@include transition;
		border-bottom: 1px solid $color-grey;
		opacity: 0.75;
		font-weight: 300;
		color: $color-grey;
    margin: 0 5px;
    font-family: monserrat;
    .nb-form-control__icon--flight:before {
				@include icon(flight, $color-grey);
			}
			.nb-form-control__icon--landscape:before {
				@include icon(landscape, $color-grey);
			}
		&--active, &:hover {
			border-bottom-color: $color-primary;
			opacity: 1;
			color: $color-white;
			background-color: $color-info;
			.nb-form-control__icon--flight:before {
				@include icon(flight, $color-white);
			}
			.nb-form-control__icon--landscape:before {
				@include icon(landscape, $color-white);
			}
		}
		&:first-of-type {
			border-top-left-radius: 5px;
		}
		&:last-of-type {
			border-top-right-radius: 5px;
		}
	}
	&--link {
		text-decoration: underline;
		cursor: pointer;
		color: $color-primary-dark;
	}
	&__preview {
		display: flex;
		@include gradient-blue;
		color: $color-white;
		margin-bottom: 10px;
	}
	&__preview-element {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px;
		@include mobile-tablet {
			display: block;
			width: 100%;
		}
	}
	&__preview--big {
		font-size: 16px;
		font-weight: bold;
		@include mobile-tablet {
			display: block;
			width: 100%;
		}	
	}
}