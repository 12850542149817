.suggestion {
	&__list {
		margin: 0;
		padding-left: 0;
		position: absolute;
		top: 100%;
		left: 26px;
		right: 0;
		z-index: 999;
    border: 1px solid #ccc;
    box-shadow: 0px 1px 4px rgba(0,0,0,0.2);
	}
	&__item {
		list-style: none;
    padding: 7.5px 5px;
    border-top: 1px solid $color-grey;
    line-height: 1.5em;
    display: flex;
    align-items: center;
    background-color: $color-white;
    cursor: pointer;
		&--active, &:hover {
			background-color: $color-grey-light;
			.suggestion__icon {
				@include icon(place, $color-error);
			}
		}
	}
	&__icon {
		@include icon-container(1.5em);
		@include icon(place, $color-grey);
    flex: 0 0 auto;
    margin-right: 5px;
	}
}