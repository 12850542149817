.login {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $color-grey-light;
	&__screen {
		width: 100vw;
		height: 100vh;
	}
	&__form {
		background-color: $color-white;
		box-shadow: 1px 1px 10px rgba(0,0,0,0.3);
		padding: 20px;
		width: calc(100vw - 40px);
		max-width: 600px;
		border-radius: 4px;
		text-align: center;
		.button {
			padding: 0;
		}
	}
	&__header {
		text-align: center;
		text-transform: capitalize;
		margin: 0 0 15px 0;
		font-size: 28px;
		font-weight: normal;
	}
}