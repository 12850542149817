.notification {
	&--tet {
    border: 1px solid red;
		padding: 15px 10px;
		background-color: #F2535A;
		font-weight: 300;
    font-size: 16px;
  	display: inline-flex;
  	color: #FFF;
	}
  &--red {
    color: red;
    margin-right: auto;
    font-style: italic;
    font-weight: bold;
    font-size: 12px; 
  }
}