.button-group {
	display: flex;
	padding: 10px 0;
	a {
		text-decoration: none;
	}
	>* {
		margin-right: 10px;
		width: 80px;
	}
}
.button {
	padding : 7px 15px;
	text-decoration: none;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-left: 10px;
	width: 100%;
	max-width: 200px;
	border-radius: 4px;
	cursor: pointer;
	/*.btn-ripple {
		width: 100%;
	}*/
	@include tablet-desktop {
		//min-width: 140px;
	}
	&:first-of-type {
		margin-left: auto;
	}
	&--link {
		text-decoration: underline;
		cursor: pointer;
	}
	&--compact {
		padding: 0;
	}
	&--danger {
		@include gradient-red;
	}
	&--success {
		@include gradient-green;
	}
	&--info {
		@include gradient-blue;
	}
	&--disabled {
		@include gradient-grey;
	}
	&--neutral {
		@include gradient-pink;
	}
	&--accept:before{
		@include icon(check, $color-white);
	}
	&--call:before{
		@include icon(local-phone, $color-white);
	}
	&--reject:before{
		@include icon(multiply, $color-white);
	}
}

.btn-ripple {
	user-select: none;
	position: relative;
	overflow: hidden;
	--ripple-top: 50%;
	--ripple-left: 50%;
	display: inline-block;
	padding: 10px 15px;
	text-align: center;
	&:after {
		pointer-events: none;
		content:'';
		display: inline-block;
		width: 100%;
		padding-top: 100%;
		background-color : rgba(255,255,255,0.3);
		position: absolute;
		top: var(--ripple-top);
		left: var(--ripple-left);
		border-radius : 100%;
		transform: scale(0);
	}
}
.animate {
	&:after {
		animation:ripple 0.3s linear;
	}
}
@keyframes ripple {
    100% {
      opacity: 0; 
      transform: scale(2.5);
  }
}