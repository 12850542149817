/* font-family */
$font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
$font-family-header: monserrat, Lucida Sans Unicode, Lucida Grande, sans-serif;

/* Colors */
$color-black                : #333 !default;
$color-black-light          : #666 !default;
$color-black-lighter        : #999 !default;

$color-white                : #fff !default;
$color-white-dark           : #f5f5f5 !default;

$color-primary-light        : #91bced !default;
$color-primary              : #589bcc !default;
$color-primary-dark         : #0277BD !default;
$color-primary-darker       : #01579B !default;

$color-info         		: #4a90e2 !default;
$color-error                : #d0021b !default;
$color-success              : #66bb6a !default;

$color-grey-lightest        : #f2f2f2 !default;
$color-grey-lighter         : #eee !default;
$color-grey-light           : #dedede !default;
$color-grey                 : #ccc !default;
$color-grey-dark            : #bbb !default;
$color-grey-darker          : #aaa !default;
$color-grey-darkest         : #999 !default;

/* Font size */
$fontsize-tiny              : 10px;
$fontsize-small             : 12px;
$fontsize-normal            : 14px;
$fontsize-large             : 16px;
$fontsize-h4                : 18px;
$fontsize-h3                : 20px;
$fontsize-h2                : 22px;
$fontsize-h1                : 24px;

/* Breakpoints */
$mobile-width               : 320px;
$tablet-width               : 768px;
$desktop-width              : 920px;

/* Navigation bar */
$navbar-width               : 300px;
