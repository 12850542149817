.time-picker {
	&__table {
		display: flex;
		flex-flow: row wrap;
		border: solid $color-grey-light;
		border-width: 1px 0 0 1px ;
		&-header {
			margin: 15px 5px 5px;
		}
		&-icon {
			width: calc(100%/7);
			@include transition(transform);
			margin: 0;
		}
		&-content {
			width: calc(100%/7*6);
			display: flex;
			flex-flow: row wrap;
			
			@include transition(transform);
		}
		&-item {
			width: calc(100%/6);
			height: 30px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			border: solid $color-grey-light;
			border-width: 0 1px 1px 0;
			cursor: pointer;
		 	user-select: none;
			@include transition(all);
			&.strong{
				color : $color-error;
			}
			&.selected {
				background-color: $color-primary;
				color: $color-white;
			}
			&.now {
				border-bottom: 1px solid $color-error;
			}
		}
	}
	&__hours {
		.time-picker__table-item {
			width: calc(100%/6);
		}
	}
	#day, #night {
		cursor: pointer;
		height : 60px;
		display: block;
		background-size: 75%!important;
		border-bottom: 1px solid $color-grey-light;
		border-right: 1px solid $color-grey-light;
		position: relative;
	}
	#day {
		@include icon(sun, $color-error);
	}
	#night {
		@include icon(moon, $color-info);
		background-size: 20px!important;
	}
}
.current-time {
	margin-top: 10px;
	font-size: 2rem;
	text-align: center;
	color: $color-primary;
}