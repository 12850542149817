.car-booking {
	&__tabs {
		&.tab__header-container {
			padding-bottom: 2px;
			background-color: $color-primary-dark;
			display: flex;
			display: -webkit-flex;
		}
		.tab__header {
			padding: 0;
			border: 0;
			flex: 1;
			-webkit-flex: 1;
			background-color: transparent;
			.btn-ripple {
				text-align: center;
				width: 100%;
				font-family: $font-family-header;
				font-size: 16px;
				@include transition(border);
				color: $color-white;
				padding: 15px 20px;
				opacity: 0.5;
				text-transform: uppercase;
				border-bottom: 1px solid $color-primary;
			}
			&.active {
				.btn-ripple {
					opacity: 1;
					border-bottom-color: $color-white;
				}
			}
		} 
	}
}