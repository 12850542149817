.chip {
	color: $color-black;
	border: 1px solid $color-grey;
	border-radius: 1.5em;
	padding-right: 0.7em;
	margin-right: 0.3em;
	margin-bottom: 4px;
	display: inline-flex;
	align-items: center;
	&:before{
		content:'';
		display: inline-block;
		width: 1.5em;
		height: 1.5em;
		margin-right: 0.3em;
		background-size: 70%!important;
		border-radius: 50%;
		background-color: $color-white!important;
		border: 1px solid $color-grey;
		border-width: 0 1px 1px 0;
	}
	&--car:before{
		@include icon(car, $color-grey-darkest);
	}
	&--repeat:before{
		@include icon(repeat, $color-grey-darkest);
	}
	&--clock:before{
		@include icon(clock, $color-grey-darkest);
	}
	&--calendar:before{
		@include icon(calendar, $color-grey-darkest);
	}
	&--direction:before{
		@include icon(direction, $color-grey-darkest);
	}
	&--payment:before{
		@include icon(payment, $color-grey-darkest);
	}
}