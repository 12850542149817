.trip-filter {
	display: flex;
	width: calc(100% - 0px);
	padding: 10px;	
	border-radius: 4px;
	justify-content: center;
	align-items: center;
	background-color: $color-grey-light;
	cursor: pointer;
	&__label {
		color: $color-black;
		width: calc(100% - 20px);
		display: inline-block;
    padding: 0 7.5px;
    height: 1.2em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
	}
	&__toggle {
		display: inline-block;
		width: 12px;
		height: 12px;
		border: 2px solid;
		border-color: transparent transparent $color-black $color-black;
		&--open {
			transform: translateY(-25%) rotate(-45deg);
		}
		&--close {
			transform: translateY(25%) rotate(135deg) ;
		}
	}
	&__driver {
		width: 100%;
		padding: 10px 7.5px;
		border-bottom: 1px solid $color-grey;
		cursor: pointer;
	}
	&__icon {
		@include icon-container(1.5em);
	}
}